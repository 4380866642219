const data = JSON.stringify({
  total_pages: 1,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Lutire',
      description: '여성의 증상성 요로감염 재발 위험 예측 노모그램',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "성관계 파트너 수*",
          answers: [
            {
              id: 1,
              desc: "",
              name: "1",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "2",
              points: 20
            },
            {
              id: 3,
              desc: "",
              name: "≥3",
              points: 35
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "장 기능",
          answers: [
            {
              id: 1,
              desc: "",
              name: "정상",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "설사",
              points: 30
            },
            {
              id: 3,
              desc: "",
              name: "변비",
              points: 50
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "병원체",
          answers: [
            {
              id: 1,
              desc: "",
              name: "그람 양성",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "그람 음성",
              points: 40
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "호르몬 상태",
          answers: [
            {
              id: 1,
              desc: "",
              name: "출산가능",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "폐경 후",
              points: 10
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "요로감염 횟수*",
          answers: [
            {
              id: 1,
              desc: "",
              name: "<3",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: ">3",
              points: 40
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "항생제 치료",
          answers: [
            {
              id: 1,
              desc: "",
              name: "아니오",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "예",
              points: 50
            }
          ]
        },
      ]
    },
  ],
})

export default data
